import React from 'react';
import { BrowserRouter as Router, Switch, Route, useParams } from "react-router-dom";
import AuthenticatedRoute from "./helpers/authenticatedRoute";
import UnauthenticatedRoute from "./helpers/unauthenticatedRoute";

// import Homepage from "./pages/homepage";
// import QuotePage from './pages/get_quotes/index';
import Login from './pages/pet_cloud/login';
import ResetPassword from './pages/pet_cloud/reset_password';
import Dashboard from './pages/pet_cloud/dashboard';
import Contact from './pages/pet_cloud/contact';
import InBox from './pages/pet_cloud/inbox';
import MyPets from './pages/pet_cloud/my_pets';
import NearMe from './pages/pet_cloud/nearme';
import Claims from './pages/pet_cloud/claims';
import GetQouteFlow from "./pages/qoute-flow";
import Index from './pages/getQoutes/index';
import { getToken, getUser } from './helpers/utilities';
import ContactUs from "./pages/contact_us";
import AboutUs from "./pages/about_us";
import PetsCloud from "./pages/pets_cloud";
import Reminders from './pages/pet_cloud/reminders';
import MyPersonalInfo from './pages/pet_cloud/my_personal_info';
import ClaimSteps from './pages/pet_cloud/claim_steps';
import FoundLostPet from './pages/lost_pet';
import PetInsurance from './pages/pet_insurance';
import PrivacyPolicy from './pages/privacy_policy';
import FaqsPage from './pages/faqs';
import FindVets from './pages/vets_page';
import PetResource from './pages/pet_resource';
import TermsOfUse from './pages/terms_of_use';
import ScheduleDemo from './pages/schedule_demo';
import UploadPolicyDocs from './pages/upload_policy_document';
import AdminDashboard from "./pages/admin_portal/admin_dashboard";
import AdminPolicies from "./pages/admin_portal/admin_policies";
import AdminClaims from "./pages/admin_portal/admin_claims";
import AdminPolicyDetails from "./pages/admin_portal/admin_policy_details";
import AdminClaimDetails from "./pages/admin_portal/admin_claim_details";
import AdminLeads from './pages/admin_portal/admin_leads';
import AdminLeadDetails from './pages/admin_portal/admin_leads_details';
import AddNewPetFlow from './pages/pet_cloud/add_new_pet';
import RenewPolicyFlow from './pages/pet_cloud/renew_policy';
import broker_payment_landing from "./pages/broker_payment_landing";


const routes = () => {
    let isAuthenticated = false;
    let returnUrl = '';
    const url = window.location.href;
    if(url.indexOf('email_link') != -1){
      returnUrl = window.location.pathname + window.location.search;
    }
    else if(url.indexOf('payement_verification') != -1){
      returnUrl = window.location.pathname + window.location.search;
    }
    else if(url.indexOf('tmrsc') != -1){
      returnUrl = window.location.pathname + window.location.search;
    }else{
      try {
        const token = getToken();
        if (token)
          isAuthenticated = true
        else
          throw new Error("User not authorized")
      } catch (e) {
        console.info(e,'error occure')
      }

      try{
        const url = window.location.href;
        
            const user = getUser();
            if(user){
              returnUrl = user.returnUrl;
              console.log('ret URL', returnUrl);
            }
            else{
              throw new Error("User not authorized")
            }
        }
        
      catch(e){
        console.info(e,'error occure')
      }
}
    return (
        <Router>
            <Switch>
                {/* <Route exact path="/" component={Homepage} /> */}
                {/* <Route exact path="/get-quote" component={QuotePage} /> */}
                {/* <Route exact path="/dashboard" component={Dashboard} /> */}
                <AuthenticatedRoute exact path="/dashboard" component={Dashboard} authFlag={{ isAuthenticated }}/>
                <AuthenticatedRoute exact path="/contact" component={Contact} authFlag={{ isAuthenticated }}/>
                <AuthenticatedRoute exact path="/my-personal-info" component={MyPersonalInfo} authFlag={{ isAuthenticated }}/>
                <AuthenticatedRoute exact path="/inbox" component={InBox} authFlag={{ isAuthenticated }}/>
                <AuthenticatedRoute exact path="/my-pets" component={MyPets} authFlag={{ isAuthenticated }}/>
                <AuthenticatedRoute exact path="/add-new-pet" component={AddNewPetFlow} authFlag={{ isAuthenticated }}/>
                <AuthenticatedRoute exact path="/reminders" component={Reminders} authFlag={{ isAuthenticated }}/>
                <AuthenticatedRoute exact path="/claim-steps" component={ClaimSteps} authFlag={{ isAuthenticated }}/>
                <AuthenticatedRoute exact path="/admin_dashboard" component={AdminDashboard} authFlag={{ isAuthenticated }}/>
                <AuthenticatedRoute exact path="/admin_policies" component={AdminPolicies} authFlag={{ isAuthenticated }}/>
                <AuthenticatedRoute exact path="/admin_claims" component={AdminClaims} authFlag={{ isAuthenticated }}/>
                <AuthenticatedRoute exact path="/admin_policies/:policyid" component={AdminPolicyDetails} authFlag={{ isAuthenticated }}/>
                <AuthenticatedRoute exact path="/admin_claims/:claimid" component={AdminClaimDetails} authFlag={{ isAuthenticated }}/>
                <AuthenticatedRoute exact path="/admin_leads" component={AdminLeads} authFlag={{ isAuthenticated }}/>
                <AuthenticatedRoute exact path="/admin_leads/new" component={AdminLeadDetails} authFlag={{ isAuthenticated }}/>
                <AuthenticatedRoute exact path="/admin_leads/:leadid" component={AdminLeadDetails} authFlag={{ isAuthenticated }}/>
                <Route exact path="/get-quote" component={Index} />
                <Route exact path="/nearme" component={NearMe} />
                <Route exact path="/claims" component={Claims} />
                <Route exact path="/pet_insurance" component={PetInsurance} />
                <Route exact path="/contact_us" component={ContactUs} />
                <Route exact path="/about_us" component={AboutUs} />
                <Route exact path="/pet_cloud" component={PetsCloud} />
                <Route exact path="/found_lost_pet" component={FoundLostPet} />
                <Route exact path="/privacy_policy" component={PrivacyPolicy} />
                <Route exact path="/faqs" component={FaqsPage} />
                <Route exact path="/find_vets" component={FindVets} />
                <Route exact path="/pet_respurce" component={PetResource} />
                <Route exact path="/terms_of_use" component={TermsOfUse} />
                <Route exact path="/schedule_demo" component={ScheduleDemo} />
                <Route exact path="/schedule_demo" component={ScheduleDemo} />
                <Route exact path="/upload_policy_docs" component={UploadPolicyDocs} />
                <Route exact path="/brokerlanding" component={broker_payment_landing} />
                <Route exact path="/brokerlanding/:paymentid" component={broker_payment_landing} />
                <Route exact path="/renew_policy/:policyid" component={RenewPolicyFlow}/>
                <UnauthenticatedRoute exact path="/login" component={Login} authFlag={{ isAuthenticated }} returnUrl={returnUrl}/>
                <UnauthenticatedRoute exact path="/login/reset" component={Login} authFlag={{ isAuthenticated }} returnUrl={returnUrl}/>
                <UnauthenticatedRoute exact path="/reset_password/:token" component={ResetPassword} authFlag={{ isAuthenticated }} returnUrl={returnUrl}/>
                <UnauthenticatedRoute exact path="/:from?" component={GetQouteFlow} authFlag={{ isAuthenticated }} returnUrl={returnUrl}/>
                <UnauthenticatedRoute exact path="/cat" component={GetQouteFlow} authFlag={{ isAuthenticated }} returnUrl={returnUrl}/>
                <UnauthenticatedRoute exact path="/dog" component={GetQouteFlow} authFlag={{ isAuthenticated }} returnUrl={returnUrl}/>
                <UnauthenticatedRoute exact path="/payement_verification/:from?" component={GetQouteFlow} authFlag={{ isAuthenticated }} returnUrl={returnUrl}/>
            </Switch>
        </Router>
    )
}

export default routes;